import { Theme } from '@mui/material';

const styleOverrideSwitch = (theme: Theme) => ({
  colorPrimary: {
    '& .Mui-checked+.MuiSwitch-track': {
      opacity: 1,
      boxSizing: 'border-box',
      backgroundColor: theme.palette.primary.main,
    },
  },
  colorSecondary: {
    '&.Mui-checked+.MuiSwitch-track': {
      opacity: 1,
      boxSizing: 'border-box',
      backgroundColor: theme.palette.secondary.main,
    },
  },
  colorError: {
    '&.Mui-checked+.MuiSwitch-track': {
      opacity: 1,
      boxSizing: 'border-box',
      backgroundColor: theme.palette.error.main,
    },
  },
  colorWarning: {
    '&.Mui-checked+.MuiSwitch-track': {
      opacity: 1,
      boxSizing: 'border-box',
      backgroundColor: theme.palette.warning.main,
    },
  },
  colorSuccess: {
    '&.Mui-checked+.MuiSwitch-track': {
      backgroundColor: theme.palette.success.main,
    },
  },
});

export default styleOverrideSwitch;
