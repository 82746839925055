import { Theme } from '@mui/material';
import colorSystem from 'src/scss/_colors-system.module.scss';

const styleOverrideIconButton = (theme: Theme) => ({
  '&.MuiIconButton-colorPrimary': {
    color: theme.palette.primary.main,
  },
  '&.MuiIconButton-colorSecondary': {
    color: theme.palette.secondary.main,
  },
  '&.MuiIconButton-colorSuccess': {
    color: theme.palette.success.main,
  },
  '&.MuiIconButton-colorWarning': {
    color: theme.palette.warning.main,
  },
  '&.MuiIconButton-colorError': {
    color: theme.palette.error.main,
  },
  '&.MuiIconButton-colorInfo': {
    color: theme.palette.info.main,
  },
  '&.Mui-disabled': {
    color: theme.palette.action.disabled,
  },
  '&.MuiIconButton-colorSuggestion': {
    color: colorSystem.ftdPrimary300,
  },
});

export default styleOverrideIconButton;
