export const LOGO_HEADER_URL = 'https://j4t6.c1.e2-10.dev/images/front-end/images/logo-company/logo-adamftd-header.png';
export const LOGO_LOGIN_URL = 'https://j4t6.c1.e2-10.dev/images/front-end/images/logo-company/logo-adamftd-login.png';
export const LOGO_INVOICE_URL = 'https://j4t6.c1.e2-10.dev/images/front-end/images/logo-company/logo-invoice.svg';
export const LOGO_COMPANY_DEFAULT_URL =
  'https://j4t6.c1.e2-10.dev/images/front-end/images/logo-company/logo-company-default.svg';
export const LOGO_GOOGLE_URL = 'https://j4t6.c1.e2-10.dev/images/front-end/images/logo-social/google.png';
export const LOGO_TRUSTPILOT_URL = 'https://j4t6.c1.e2-10.dev/images/front-end/images/logo-social/trustpilot.png';
export const LOGO_YELP_URL = 'https://j4t6.c1.e2-10.dev/images/front-end/images/logo-social/yelp.png';
export const BACKGROUND_LOGIN = 'https://j4t6.c1.e2-10.dev/images/front-end/images/background-login.png';
export const BANNER_NOTIFY_HOME_PAGE =
  'https://j4t6.c1.e2-10.dev/images/front-end/images/icons/banner-notify-home-page.png';
export const ICON_BANNER_HOME_PAGE =
  'https://j4t6.c1.e2-10.dev/images/front-end/images/icons/icon-banner-home-page.png';
export const ICON_SUPPORT_NO_DATA_COMPANY =
  'https://j4t6.c1.e2-10.dev/images/front-end/images/icons/icon-support-no-data-company.png';
export const ICON_QUICK_TIP_SEARCH_COMPANY =
  'https://j4t6.c1.e2-10.dev/images/front-end/images/icons/icon-quick-tips-search-company.png';
export const ICON_NO_MATCH_SEARCH_COMPANY =
  'https://j4t6.c1.e2-10.dev/images/front-end/images/icons/icon-no-matching-search-company.png';
export const ICON_NO_DATA_COMPANY = 'https://j4t6.c1.e2-10.dev/images/front-end/images/icons/icon-no-data-company.png';
export const ICON_AVATAR_PEOPLE_DEFAULT =
  'https://j4t6.c1.e2-10.dev/images/front-end/images/icons/icon-avatar-people-default.png';
export const ICON_NO_PLAN_AND_USAGE =
  'https://j4t6.c1.e2-10.dev/images/front-end/images/icons/icon-no-plan-and-usage.png';
export const ICON_DISCLAIMER_COMPANY_DATA =
  'https://j4t6.c1.e2-10.dev/images/front-end/images/icons/icon-disclaimer-company-data.png';
export const ICON_PAID_TO_UNBLOCK_CONTENT =
  'https://j4t6.c1.e2-10.dev/images/front-end/images/icons/icon-paid-to-unblock-content.png';
export const ICON_DO_NOT_HAVE_ENOUGH_CREDIT =
  'https://j4t6.c1.e2-10.dev/images/front-end/images/icons/icon-do-not-have-enough-credit.png';
export const ICON_UPGRADE_PLAN = 'https://j4t6.c1.e2-10.dev/images/front-end/images/icons/icon-upgrade-plan.png';
export const ICON_CONFIRM_GET_TRIAL =
  'https://j4t6.c1.e2-10.dev/images/front-end/images/icons/icon-confirm-get-trial.png';
export const ICON_WALLET_PRICING_PLAN =
  'https://j4t6.c1.e2-10.dev/images/front-end/images/icons/icon-wallet-pricing-plan.png';
export const BANNER_BOOKING_DEMO_CALENDAR =
  'https://j4t6.c1.e2-10.dev/images/front-end/images/icons/banner-booking-demo-calendar.png';

export const DEFAULT_AVATARS = [
  'https://j4t6.c1.e2-10.dev/images/front-end/images/avatar-user-default/avatar1.png',
  'https://j4t6.c1.e2-10.dev/images/front-end/images/avatar-user-default/avatar2.png',
  'https://j4t6.c1.e2-10.dev/images/front-end/images/avatar-user-default/avatar3.png',
  'https://j4t6.c1.e2-10.dev/images/front-end/images/avatar-user-default/avatar4.png',
  'https://j4t6.c1.e2-10.dev/images/front-end/images/avatar-user-default/avatar5.png',
  'https://j4t6.c1.e2-10.dev/images/front-end/images/avatar-user-default/avatar6.png',
  // "https://j4t6.c1.e2-10.dev/images/front-end/images/avatar-user-default/avatar7.png",
  // "https://j4t6.c1.e2-10.dev/images/front-end/images/avatar-user-default/avatar8.png",
  // "https://j4t6.c1.e2-10.dev/images/front-end/images/avatar-user-default/avatar9.png",
  // "https://j4t6.c1.e2-10.dev/images/front-end/images/avatar-user-default/avatar10.png",
  // "https://j4t6.c1.e2-10.dev/images/front-end/images/avatar-user-default/avatar11.png",
  // "https://j4t6.c1.e2-10.dev/images/front-end/images/avatar-user-default/avatar12.png"
] as Array<string>;

export const TARIFFS_AND_RULE_DEFAULT_PAGE_IMAGES = {
  shipping_blue_box: 'https://j4t6.c1.e2-10.dev/images/front-end/images/tariffs-and-rules-page/shipping_blue_box.svg',
  avoid_costly_penalties:
    'https://j4t6.c1.e2-10.dev/images/front-end/images/tariffs-and-rules-page/avoid_costly_penalties.svg',
  optimize_market_entry:
    'https://j4t6.c1.e2-10.dev/images/front-end/images/tariffs-and-rules-page/optimize_market_entry.svg',
  enhance_supply_chain_efficiency:
    'https://j4t6.c1.e2-10.dev/images/front-end/images/tariffs-and-rules-page/enhance_supply_chain_efficiency.svg',
};

export const AI_HS_CODE_LABELING_IMAGES = {
  banner:
    'https://j4t6.c1.e2-10.dev/images/front-end%2Fimages%2Fai-hs-code-labeling%2FHS%20Code%20Labeling%20Banner.webp',
  single_prediction_cover:
    'https://j4t6.c1.e2-10.dev/images/front-end%2Fimages%2Fai-hs-code-labeling%2FSingle%20prediction%20cover.png',
};
