import { Theme, alpha } from '@mui/material';
import colorSystem from 'src/scss/_colors-system.module.scss';

const styleOverrideChip = (theme: Theme) => ({
  colorDefault: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.action.selected,
    '&:hover': {
      backgroundColor: colorSystem.neutroState200,
    },
  },
  colorPrimary: {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.07),
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
    },
  },
  colorSecondary: {
    color: theme.palette.secondary.main,
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  colorWarning: {
    color: theme.palette.warning.main,
    backgroundColor: alpha(theme.palette.warning.main, 0.1),
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.warning.dark,
    },
  },
  colorInfo: {
    color: theme.palette.info.main,
    backgroundColor: alpha(theme.palette.info.main, 0.1),
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.info.dark,
    },
  },
  colorError: {
    color: theme.palette.error.main,
    backgroundColor: alpha(theme.palette.error.main, 0.1),
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.dark,
    },
  },
  colorSuccess: {
    color: theme.palette.success.main,
    backgroundColor: alpha(theme.palette.success.main, 0.1),
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.main,
    },
  },
  //=================Avatar=================//
  avatarColorPrimary: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
  },
  avatarColorSecondary: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.dark,
  },
  avatarColorError: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.dark,
  },
  avatarColorInfo: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.info.dark,
  },
  avatarColorSuccess: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.dark,
  },
  avatarColorWarning: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.warning.dark,
  },
  avatarColorDefault: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.text.disabled,
  },
  //=================Outline=================//
  outlined: {
    backgroundColor: 'transparent',
  },
  outlinedPrimary: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.07),
      color: theme.palette.primary.main,
    },
  },
  outlinedSecondary: {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.1),
      color: theme.palette.secondary.main,
    },
  },
  outlinedError: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.1),
      color: theme.palette.error.main,
    },
  },
  outlinedWarning: {
    borderColor: theme.palette.warning.main,
    color: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.warning.main, 0.1),
      color: theme.palette.warning.main,
    },
  },
  outlinedInfo: {
    borderColor: theme.palette.info.main,
    color: theme.palette.info.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.info.main, 0.1),
      color: theme.palette.info.main,
    },
  },
  outlinedSuccess: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.success.main, 0.1),
      color: theme.palette.success.main,
    },
  },
});

export default styleOverrideChip;
