import { Theme, alpha } from '@mui/material';

const styleOverrideButtonGroup = (theme: Theme) => ({
  '& .MuiButton-containedPrimary': {
    borderColor: theme.palette.primary.dark,
  },
  '& .MuiButton-containedSecondary': {
    borderColor: theme.palette.secondary.dark,
  },
  '& .MuiButton-containedSuccess': {
    borderColor: theme.palette.success.dark,
  },
  '& .MuiButton-containedWarning': {
    borderColor: theme.palette.warning.dark,
  },
  '& .MuiButton-containedInfo': {
    borderColor: theme.palette.info.dark,
  },
  '& .MuiButton-containedError': {
    borderColor: theme.palette.error.dark,
  },
  '& .MuiButton-textPrimary': {
    borderColor: alpha(theme.palette.primary.main, 0.5),
  },
  '& .MuiButton-textSecondary': {
    borderColor: alpha(theme.palette.secondary.main, 0.5),
  },
  '& .MuiButton-textSuccess': {
    borderColor: alpha(theme.palette.success.main, 0.5),
  },
  '& .MuiButton-textError': {
    borderColor: alpha(theme.palette.error.main, 0.5),
  },
  '& .MuiButton-textInfo': {
    borderColor: alpha(theme.palette.info.main, 0.5),
  },
  '& .MuiButton-textWarning': {
    borderColor: alpha(theme.palette.warning.main, 0.5),
  },
});

export default styleOverrideButtonGroup;
