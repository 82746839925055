import { AlertProps } from '@mui/material';
import { ReactNode } from 'react';
import { create } from 'zustand';

export interface ISnackbarState extends AlertProps {
  open: boolean;
  description?: ReactNode;
  title?: string;
}

interface IAction {
  dispatch: (type: ISnackbarState) => void;
}

const useSnackbarStore = create<ISnackbarState & IAction>((set) => ({
  open: false,
  description: '',
  title: '',
  dispatch: (state: ISnackbarState) => set((prevState) => ({ ...prevState, ...state })),
}));
export default useSnackbarStore;
