import { Theme } from '@mui/material';
import colorSystem from 'src/scss/_colors-system.module.scss';

const styleOverrideAlert = (theme: Theme) => ({
  '&.MuiAlert-colorError': {
    backgroundColor: theme.palette.error.main,
  },
  '&.MuiAlert-colorInfo': {
    backgroundColor: theme.palette.info.main,
  },
  '&.MuiAlert-colorSuccess': {
    backgroundColor: theme.palette.success.main,
  },
  '&.MuiAlert-colorWarning': {
    backgroundColor: theme.palette.warning.main,
  },
  '&.MuiAlert-outlinedError': {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.error.main,
    color: colorSystem.red500,
  },
  '&.MuiAlert-outlinedInfo': {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.info.main,
    color: colorSystem.blue500,
  },
  '&.MuiAlert-outlinedSuccess': {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.success.main,
    color: colorSystem.lightGreen500,
  },
  '&.MuiAlert-outlinedWarning': {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.warning.main,
    color: colorSystem.orange500,
  },
  '&.MuiAlert-standardError': {
    backgroundColor: colorSystem.red50,
    color: colorSystem.red500,
  },
  '&.MuiAlert-standardInfo': {
    backgroundColor: colorSystem.blue50,
    color: colorSystem.blue500,
  },
  '&.MuiAlert-standardSuccess': {
    backgroundColor: colorSystem.lightGreen50,
    color: colorSystem.lightGreen500,
  },
  '&.MuiAlert-standardWarning': {
    backgroundColor: colorSystem.orange50,
    color: colorSystem.orange500,
  },
});

export default styleOverrideAlert;
