import React, { useMemo } from 'react';

// material-ui
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// project import
import Palette from './palette';
import Typography from './typography';

import componentStyleOverrides from './compStyleOverride';
import customShadows from './shadows';
import { customBreakpoint } from './breakpoint';

export default function ThemeCustomization({ children }: { children: React.ReactNode }) {
  const themePalette = useMemo(() => Palette(), []);

  const themeTypography = useMemo(() => Typography(themePalette), [themePalette]);

  const themeCustomShadows = useMemo(() => customShadows(themePalette), [themePalette]);

  const themeCustomBreakpoint = useMemo(() => customBreakpoint(), []);

  const gap = (spacing: number) => parseInt(themePalette.spacing(spacing), 10);
  const themeOptions = useMemo(
    () => ({
      gap,
      breakpoints: themeCustomBreakpoint,
      palette: themePalette.palette,
      mixins: {
        toolbar: {
          minHeight: '48px',
          padding: '16px',
          '@media (min-width: 600px)': {
            minHeight: '48px',
          },
        },
      },
      typography: themeTypography,
      customShadows: themeCustomShadows,
      shape: {
        borderRadius: 4,
      },
    }),
    [themePalette, themeCustomShadows, themeTypography],
  );

  const themes = createTheme(themeOptions);

  themes.components = useMemo(() => componentStyleOverrides(themes), [themes]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
