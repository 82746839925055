import { Theme } from '@mui/material';

const styleOverrideBadge = (theme: Theme) => ({
  colorError: {
    backgroundColor: theme.palette.error.main,
  },
  colorInfo: {
    backgroundColor: theme.palette.info.main,
  },
  colorPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  colorSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  colorWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  colorSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
});

export default styleOverrideBadge;
