import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';

const onError = (error: ExpectedAny, query: ExpectedAny) => {
  if (!query?.meta?.ignoreError) {
    console.error(error.message);
  }
};

const queryCache: ExpectedAny = new QueryCache({
  onError,
});

const mutationCache: ExpectedAny = new MutationCache({
  onError,
});

export const queryClient: QueryClient = new QueryClient({
  queryCache,
  mutationCache,
});
