import { Box } from '@mui/material';
import { LOGO_HEADER_URL } from 'src/utils/constants/imagesUrl';

const GlobalLoading = () => {
  return (
    <Box
      display={'grid'}
      sx={{ placeItems: 'center', animation: 'ease-in-out', animationDuration: 300 }}
      width={'100dvw'}
      height={'100dvh'}
    >
      <Box width={270} height={200} src={LOGO_HEADER_URL} component="img" />
    </Box>
  );
};

export default GlobalLoading;
